
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import type { FlagCountry } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmFormLine,
  },
  setup() {
    const company = ref('Textmagic')
    const email = ref('textmagic@gmail.com')
    const address1 = ref('Salisbury House')
    const address2 = ref('Station Road')
    const city = ref('Cambridge')
    const firstName = ref('Robert')
    const lastName = ref('Overrit')
    const region = ref('Cabridgeshire')
    const zip = ref('CB1 2LA')
    const country = ref<FlagCountry>(phoneCountryVariant[0])
    const vat = ref('8454875822')

    return {
      firstName,
      vat,
      lastName,
      email,
      country,
      company,
      address1,
      address2,
      city,
      region,
      zip,
    }
  },
})
